import { Form, Input } from "antd";
import ColorPicker from "../ColorPicker";

export const getCategoryCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kategori Adı",
      dataIndex: "name",
      key: "name",
    },
  ];
};

export const getCategoryCrudPageFormItems = () => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kategori Adı"
        name="name"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Renk"
        name="color"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <ColorPicker />
      </Form.Item>
    </>
  );
};
