import api from "./AxiosConfig";
import { getCrudApi } from "./CrudApi";

export const userCrudApi = getCrudApi("users");
export const categoryCrudApi = getCrudApi("categories");
export const accountCrudApi = getCrudApi("accounts");
export const accountItemCrudApi = getCrudApi("account-items");
export const productCrudApi = getCrudApi("products");
export const woCrudApi = getCrudApi("work-orders");
export const invoiceCrudApi = getCrudApi("invoices");
export const noteCrudApi = getCrudApi("notes");
export const ledgerCrudApi = getCrudApi("ledgers");
export const proformaHistoryCrudApi = getCrudApi("proforma-histories");

export const authApi = {
  login: (username: string, password: string) => {
    return api
      .post("api/login_check", { username: username, password: password })
      .then((response: any) => {
        return response.data;
      });
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("api/refresh-token", { refreshToken: refreshToken })
      .then((response: any) => {
        return response.data;
      });
  },
};

export const otherApi = {
  constants: () => {
    return api.get("api/constants").then((response: any) => {
      return response.data;
    });
  },
  analyseData: (type: "pie" | "bar", range: any) => {
    return api
      .post("api/analyse-data", { type: type, range: range })
      .then((response: any) => response.data);
  },
  exportExcel: (id: string) => {
    return api.get("api/export-wo/" + id).then((response: any) => {
      return response.data;
    });
  },
  calculateGain: (id: string) => {
    return api.get("api/calculate-gain/" + id).then((response: any) => {
      return response.data;
    });
  },
  applyGain: (id: string, values: any) => {
    return api.post("api/apply-gain/" + id, values).then((response: any) => {
      return response.data;
    });
  },
  getOpenedWOs: (categoryId: string) => {
    return api.get("api/open-wo/" + categoryId).then((response: any) => {
      return response.data;
    });
  },
  exportOpenedWOs: (categoryId: string) => {
    return api.get("api/export-open-wo/" + categoryId).then((response: any) => {
      return response.data;
    });
  },
  exportOfferWOs: (values: any) => {
    return api.post("api/create-proforma", values).then((response: any) => {
      return response.data;
    });
  },
  exportExtractAccount: (values: any) => {
    return api
      .post("api/export-extract-account", values)
      .then((response: any) => response.data);
  },
  convertToInvoice: (values: any) => {
    return api
      .post("api/convert-to-invoice", values)
      .then((response: any) => response.data);
  },
};

export const accountCrudExtendedApi = {
  getStorage: () => {
    return api.get("api/storage").then((response: any) => {
      return response.data;
    });
  },
  getAccItemsWithAccId: (id: any) => {
    return api
      .get("api/accounts/" + parseInt(id) + "/account-items")
      .then((response: any) => {
        return response.data;
      });
  },
};
export const woCrudExtendedApi = {
  getRoleUserWorkOrders: () => {
    return api.get("api/work-orders/users").then((response: any) => {
      return response.data;
    });
  },
};
