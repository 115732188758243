import { Col, Row } from "antd";

const CenteredCol = (props: any) => {
  return (
    <Col {...props} span={props.span}>
      <Row justify="center">{props.children}</Row>
    </Col>
  );
};

export default CenteredCol;
