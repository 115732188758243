import Cookies from "js-cookie";
import { authApi } from "../client/EntityApi";

export const AuthHelper = {
  login: (username: string, password: string) => {
    authApi.login(username, password).then((response) => {
      Cookies.set("user", JSON.stringify(response.user));
      Cookies.set("accessToken", response.accessToken);
      Cookies.set("refreshToken", response.refreshToken);
      window.location.reload();
    });
  },
  refreshToken: (refreshToken: string) => {
    authApi.refreshToken(refreshToken).then((response) => {
      Cookies.set("user", JSON.stringify(response.user));
      Cookies.set("accessToken", response.accessToken);
      Cookies.set("refreshToken", response.refreshToken);
      window.location.reload();
    });
  },
  logout: () => {
    Cookies.remove("user");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    window.location.replace(window.location.origin + "/admin");
  },
  getUser: () => {
    const user = Cookies.get("user");
    return user ? JSON.parse(user) : undefined;
  },
  getUserField: (field: string) => {
    const user = AuthHelper.getUser();
    return user ? user[field] : undefined;
  },
  isLoggedIn: () => {
    return Boolean(Cookies.get("accessToken"));
  },
  checkRole: (role: string) => {
    const roles = AuthHelper.getUserField("roles");
    return roles?.includes(role) || roles?.includes("ROLE_SUPER_ADMIN");
  },
};
