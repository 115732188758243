import { Form, Input } from "antd";

export const getProductCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ürün Adı",
      dataIndex: "name",
      key: "name",
    },
  ];
};

export const getProductCrudPageFormItems = () => {
  return (
    <Form.Item
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      label="Ürün Adı"
      name="name"
      rules={[
        { required: true, message: "Boş bırakılamaz!" },
        { max: 255, message: "En fazla 255 karakter olabilir!" },
      ]}
    >
      <Input />
    </Form.Item>
  );
};
