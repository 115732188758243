import { Tag } from "antd";
import dayjs from "dayjs";

export const getProformaHistoryCPColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Fatura Adı",
      dataIndex: "invoice",
      key: "invoice",
      render: (value: any) =>
        value ? value.name : <Tag color="red">Faturalanmadı</Tag>,
      defaultFilteredValue: [false],
      filters: [
        {
          text: "Faturalanmış",
          value: true,
        },
        {
          text: "Faturalanmamış",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isInvoiced === value,
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Dosya Adı",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    },
  ];
};
