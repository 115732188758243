import { DatePicker } from "antd";

const DatePick = (props: any) => {
  return (
    <DatePicker
      {...props}
      placeholder="Tarih Seçiniz"
      format="DD/MM/YYYY"
      style={{ width: "100%" }}
    />
  );
};

export default DatePick;
