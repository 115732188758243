import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface ICrudPageV2Props {
  api: any;
  entityLabel: string;
  getAll: () => any;
  searchKey: string;
  columns: any[];
  expandable?: any;
  proceses?: any;
  hideDefaultProceses?: boolean;
  titleProceses?: any;
  hideDefaultTitleProcess?: boolean;
  hideSearchBar?: boolean;
  hideEditProcess?: (row: any) => boolean;
  hideDeleteProcess?: (row: any) => boolean;
  deleteModalMessage?: string;
  addForm: any;
  formAdd?: FormInstance;
  formEdit?: FormInstance;
  editForm: any;
  setEditFields: (row: any) => any;
  beforeAddManipulationOnValues?: (values: any) => any;
  beforeEditManipulationOnValues?: (values: any) => any;
  afterAddOperation?: () => void;
  afterEditOperation?: () => void;
  afterRefreshOperation?: (data: any[]) => void;
}

interface IId {
  id: number;
}

export interface ICrudPageV2RefMethods {
  refreshData: () => void;
}

const CrudPageV2 = forwardRef((props: ICrudPageV2Props, ref) => {
  const [entities, setEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState<IId>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [addDialogIsOpen, setAddDialogIsOpen] = useState<boolean>(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState<boolean>(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState<boolean>(false);

  const refreshData = () => {
    setDataLoading(true);
    props
      .getAll()
      .then((response: any) => {
        setEntities(response["hydra:member"]);
        setFilteredEntities(response["hydra:member"]);
        if (props.afterRefreshOperation) {
          props.afterRefreshOperation(response["hydra:member"]);
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    refreshData: refreshData,
  }));

  const callbackReferences = {
    setEntities,
    setFilteredEntities,
    setSelectedEntity,
    setDataLoading,
  };

  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const usedAddForm = props.formAdd ? props.formAdd : formAdd;
  const usedEditForm = props.formEdit ? props.formEdit : formEdit;

  useEffect(() => {
    props
      .getAll()
      .then((response: any) => {
        setEntities(response["hydra:member"]);
        setFilteredEntities(response["hydra:member"]);
        if (props.afterRefreshOperation) {
          props.afterRefreshOperation(response["hydra:member"]);
        }
      })
      .finally(() => {
        setDataLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = (value: string) => {
    if (value) {
      const filteredData = entities.filter((o) => {
        const searchField = o[props.searchKey] as string;
        return searchField
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      });

      setFilteredEntities(filteredData);
    } else {
      setFilteredEntities(entities);
    }
  };

  const processRenderFunction = (text: any, row: any) => {
    return (
      <>
        {props.proceses ? props.proceses(row, callbackReferences) : ""}
        {props.hideDefaultProceses ? (
          <></>
        ) : (
          <>
            {props.hideEditProcess && props.hideEditProcess(row) ? (
              <></>
            ) : (
              <Tooltip title={props.entityLabel + " Düzenle"}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={() => {
                    setSelectedEntity(row);
                    usedEditForm.setFieldsValue(props.setEditFields(row));
                    setEditDialogIsOpen(true);
                  }}
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {props.hideDeleteProcess && props.hideDeleteProcess(row) ? (
              <></>
            ) : (
              <Tooltip title={props.entityLabel + " Sil"}>
                <Button
                  onClick={() => {
                    setSelectedEntity(row);
                    setDeleteDialogIsOpen(true);
                  }}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Table
        expandable={props.expandable ? props.expandable : undefined}
        loading={dataLoading}
        dataSource={filteredEntities}
        rowKey="id"
        columns={[
          ...props.columns,
          {
            title: "İşlemler",
            render: processRenderFunction,
          },
        ]}
        title={() => {
          return (
            <Row>
              <Col span={8}>
                <Row justify="start">
                  <b>{props.entityLabel} Listesi</b>
                </Row>
              </Col>
              <Col span={16}>
                <Row justify="end" align="middle">
                  <Col>
                    {props.hideSearchBar ?? (
                      <Input.Search
                        style={{ width: "100%" }}
                        size="large"
                        placeholder={props.entityLabel + " Ara"}
                        enterButton
                        onSearch={search}
                      />
                    )}
                  </Col>
                  <Col offset={1}>
                    {props.titleProceses
                      ? props.titleProceses(callbackReferences)
                      : ""}
                    {props.hideDefaultTitleProcess ? (
                      <></>
                    ) : (
                      <Tooltip title={props.entityLabel + " Ekle"}>
                        <Button
                          onClick={() => {
                            setAddDialogIsOpen(true);
                          }}
                          shape="circle"
                          icon={<PlusOutlined />}
                        />
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={addDialogIsOpen}
        title={props.entityLabel + " Oluştur"}
        onOk={() => {
          usedAddForm.validateFields().then((values) => {
            if (props.beforeAddManipulationOnValues) {
              values = props.beforeAddManipulationOnValues(values);
            }
            props.api.create(values).then(() => {
              setDataLoading(true);
              props
                .getAll()
                .then((response: any) => {
                  setEntities(response["hydra:member"]);
                  setFilteredEntities(response["hydra:member"]);
                  usedAddForm.resetFields();
                  setAddDialogIsOpen(false);
                  if (props.afterRefreshOperation) {
                    props.afterRefreshOperation(response["hydra:member"]);
                  }
                })
                .finally(() => {
                  setDataLoading(false);
                  if (props.afterAddOperation) {
                    props.afterAddOperation();
                  }
                });
            });
          });
        }}
        onCancel={() => {
          usedAddForm.resetFields();
          if (props.afterAddOperation) {
            props.afterAddOperation();
          }
          setAddDialogIsOpen(false);
        }}
        okText="Oluştur"
        cancelText="Vazgeç"
      >
        <Form form={usedAddForm} autoComplete="off">
          {props.addForm}
        </Form>
      </Modal>
      <Modal
        maskClosable={false}
        forceRender
        visible={editDialogIsOpen}
        title={props.entityLabel + " Düzenle"}
        onOk={() => {
          usedEditForm.validateFields().then((values) => {
            if (selectedEntity) {
              if (props.beforeEditManipulationOnValues) {
                values = props.beforeEditManipulationOnValues(values);
              }

              props.api.edit(selectedEntity?.id, values).then(() => {
                setDataLoading(true);
                props
                  .getAll()
                  .then((response: any) => {
                    setEntities(response["hydra:member"]);
                    setFilteredEntities(response["hydra:member"]);
                    usedEditForm.resetFields();
                    setEditDialogIsOpen(false);
                    if (props.afterRefreshOperation) {
                      props.afterRefreshOperation(response["hydra:member"]);
                    }
                  })
                  .finally(() => {
                    setDataLoading(false);
                    if (props.afterEditOperation) {
                      props.afterEditOperation();
                    }
                  });
              });
            }
          });
        }}
        onCancel={() => {
          setSelectedEntity(undefined);
          usedEditForm.resetFields();
          if (props.afterEditOperation) {
            props.afterEditOperation();
          }
          setEditDialogIsOpen(false);
        }}
        okText="Düzenle"
        cancelText="Vazgeç"
      >
        <Form form={usedEditForm} autoComplete="off">
          {props.editForm}
        </Form>
      </Modal>
      <Modal
        forceRender
        visible={deleteDialogIsOpen}
        title={props.entityLabel + " Sil"}
        onOk={() => {
          if (selectedEntity) {
            props.api.delete(selectedEntity.id).then(() => {
              setDataLoading(true);
              props
                .getAll()
                .then((response: any) => {
                  setEntities(response["hydra:member"]);
                  setFilteredEntities(response["hydra:member"]);
                  setSelectedEntity(undefined);
                  setDeleteDialogIsOpen(false);
                  if (props.afterRefreshOperation) {
                    props.afterRefreshOperation(response["hydra:member"]);
                  }
                })
                .finally(() => setDataLoading(false));
            });
          }
        }}
        onCancel={() => {
          setSelectedEntity(undefined);
          setDeleteDialogIsOpen(false);
        }}
        okText="Sil"
        cancelText="Vazgeç"
      >
        {props.deleteModalMessage
          ? props.deleteModalMessage
          : "Silmek istediğinize emin misiniz? Bu işlem geri alınamaz."}
      </Modal>
    </>
  );
});

export default CrudPageV2;
