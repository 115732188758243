import dayjs from "dayjs";
import { Form, Input, InputNumber, Select } from "antd";
import { Util } from "../../helper/Util";

export const getAccountItemCrudPageColumns = (types: any) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Kayıt Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kayıt Tutarı",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Kayıt Tipi",
      dataIndex: "type",
      key: "type",
      render: (value: any) => Util.getConstantValue(value, types),
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    },
    {
      title: "Düzenlenme Tarihi",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    },
  ];
};

export const getAccountItemCrudPageFormItems = (types: any, ıri: string) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kayıt Adı"
        name="name"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kayıt Tutarı"
        name="amount"
        initialValue="0"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kayıt Tipi"
        name="type"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select options={types} />
      </Form.Item>
      <Form.Item
        hidden
        name="account"
        initialValue={ıri}
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
