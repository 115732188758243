import { productCrudApi } from "../../client/EntityApi";
import {
  getProductCrudPageColumns,
  getProductCrudPageFormItems,
} from "../../components/crud/ProductCrudPageComponents";
import CrudPageV2 from "./CrudPageV2";

const ProductCrudPage = () => {
  return (
    <CrudPageV2
      getAll={productCrudApi.getAll}
      api={productCrudApi}
      entityLabel="Ürün"
      searchKey="name"
      columns={getProductCrudPageColumns()}
      addForm={getProductCrudPageFormItems()}
      editForm={getProductCrudPageFormItems()}
      setEditFields={(row) => {
        return { name: row.name };
      }}
    />
  );
};

export default ProductCrudPage;
