import { Tag } from "antd";
import { cellStyle } from "../../helper/Util";
import dayjs from "dayjs";

export const getWOUserCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İş Emri",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Onay T.",
      dataIndex: "approvedDate",
      key: "approvedDate",
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>
          {value ? dayjs(value).format("DD MMM YYYY") : "X"}
        </Tag>
      ),
    },
    {
      title: "Fatura T.",
      dataIndex: "invoicedDate",
      key: "invoicedDate",
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>
          {value ? dayjs(value).format("DD MMM YYYY") : "X"}
        </Tag>
      ),
    },
  ];
};

export const getWOUserCrudPageExpanded = (record: any) => {
  return (
    <>
      <table style={{ minWidth: "100%", marginBottom: "1em" }}>
        <tbody>
          <tr>
            <th style={cellStyle}>Ürün Adı</th>
            <th style={cellStyle}>Miktar</th>
            <th style={cellStyle}>Birim</th>
          </tr>
          {record.workOrderItems.map((workOrderItem: any, index: any) => {
            return (
              <tr key={index}>
                <td style={cellStyle}>{workOrderItem.name}</td>
                <td style={cellStyle}>{workOrderItem.quantity}</td>
                <td style={cellStyle}>{workOrderItem.unitName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {record.description && (
        <>
          <b>Açıklama: </b> {record.description}
        </>
      )}
    </>
  );
};
