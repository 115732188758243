import {
  getAccountCrudPageColumns,
  getAccountCrudPageFormItems,
} from "../../components/crud/AccountCrudPageComponents";
import {
  accountCrudApi,
  accountCrudExtendedApi,
  otherApi,
} from "../../client/EntityApi";
import CrudPageV2 from "./CrudPageV2";
import { Tooltip, Button, Modal, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { CalculatorOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CrudPageProcessButton from "../../components/CrudPageProcessButton";
import DatePick from "../../components/DatePick";

const AccountCrudPage = () => {
  const navigate = useNavigate();
  const [accountTypes, setAccountTypes] = useState([]);
  const [storage, setStorage] = useState(0);
  const [storageLoading, setStorageLoading] = useState(true);
  const [extractModalIsOpen, setExtractModalIsOpen] = useState(false);

  const [extractForm] = Form.useForm();

  useEffect(() => {
    otherApi.constants().then((response) => {
      setAccountTypes(response["accountTypes"]);
    });
  }, []);

  const updateStorage = () => {
    setStorageLoading(true);
    accountCrudExtendedApi
      .getStorage()
      .then((response) => setStorage(response.storage))
      .finally(() => setStorageLoading(false));
  };

  const onExtractModalCancel = () => {
    extractForm.resetFields();
    setExtractModalIsOpen(false);
  };

  return (
    <>
      <h3 style={{ marginRight: "1em" }}>
        <b>
          Kasa : {storageLoading ? "Yükleniyor.." : storage.toFixed(2) + " TL"}
        </b>
      </h3>
      <CrudPageV2
        getAll={accountCrudApi.getAll}
        api={accountCrudApi}
        entityLabel="Cari"
        searchKey="name"
        columns={getAccountCrudPageColumns(accountTypes)}
        addForm={getAccountCrudPageFormItems(accountTypes)}
        editForm={getAccountCrudPageFormItems(accountTypes)}
        afterRefreshOperation={updateStorage}
        proceses={(row: any) => {
          return (
            <>
              <Tooltip title={"Cari Detayı"}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={() => navigate("/admin/cari-detay/" + row.id)}
                  shape="circle"
                  icon={<CalculatorOutlined />}
                />
              </Tooltip>
              <CrudPageProcessButton
                tooltipText="Ekstre Oluştur"
                onClick={() => {
                  // setSelectedAccount(row);
                  extractForm.setFieldsValue({ accountId: row.id });
                  setExtractModalIsOpen(true);
                }}
                icon={<FilePdfOutlined />}
              />
            </>
          );
        }}
        setEditFields={(row) => {
          return {
            name: row.name,
            type: row.type,
            isActive: row.isActive,
          };
        }}
      />
      <Modal
        maskClosable={false}
        forceRender
        visible={extractModalIsOpen}
        title="Ekstre Oluştur"
        onOk={() => {
          extractForm
            .validateFields()
            .then((values) => {
              otherApi
                .exportExtractAccount(values)
                .then((response) => window.open("/" + response.path, "_blank"));
            })
            .finally(() => onExtractModalCancel());
        }}
        onCancel={() => onExtractModalCancel()}
        okText="Ekstre Çıktısı Al"
        cancelText="Vazgeç"
      >
        <Form form={extractForm} layout="vertical">
          <Form.Item
            label="Ekstrenin Başlangıç Tarihi"
            name="extractDate"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <DatePick />
          </Form.Item>
          <Form.Item
            hidden
            name="accountId"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AccountCrudPage;
