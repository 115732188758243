import { ResponsiveBar } from "@nivo/bar";
import { Row, DatePicker, Dropdown, Menu, Checkbox } from "antd";
import { useEffect, useRef, useState } from "react";
import CenteredCol from "../CenteredCol";
import CrudPageProcessButton from "../CrudPageProcessButton";
import {
  SettingOutlined,
  DownloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Util } from "../../helper/Util";
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { otherApi } from "../../client/EntityApi";
import SettingSlider from "./SettingSlider";

const BarChart = () => {
  const { RangePicker } = DatePicker;
  const chartRef = useRef(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<RangeValue<Moment>>(null);
  const [settings, setSettings] = useState({
    margin: { top: 50, right: 50, bottom: 50, left: 70 },
    bottomOffset: 32,
    leftOffset: -60,
    labelSkipHeight: 10,
    enableLabel: true,
    enableGridX: true,
    enableGridY: true,
  });

  useEffect(() => {
    if (range === null) return;
    setLoading(true);
    otherApi
      .analyseData("bar", range)
      .then((response) => setData(response))
      .finally(() => setLoading(false));
  }, [range]);

  const onChange = (value: any, name: string) => {
    setSettings({ ...settings, [name]: value });
  };

  return (
    <div ref={chartRef} style={{ backgroundColor: "white" }}>
      <Row justify="center" style={{ padding: "1em" }}>
        <CenteredCol span={5}>
          <h3 style={{ color: "#414a4c" }}>
            <b>Kategori Bazlı İş Grafiği</b>
          </h3>
        </CenteredCol>
        <CenteredCol offset={10} span={6}>
          <RangePicker format={"DD/MM/YYYY"} onChange={(e) => setRange(e)} />
        </CenteredCol>
        <CenteredCol offset={1} span={1}>
          <CrudPageProcessButton
            tooltipText={"Grafik Çıktısı Al"}
            icon={<DownloadOutlined />}
            onClick={() =>
              Util.download(chartRef, "Kategori Bazlı İş Grafiği.png")
            }
          />
        </CenteredCol>
        <CenteredCol span={1}>
          <Dropdown
            disabled={data.length === 0}
            trigger={["click"]}
            overlay={
              <Menu style={{ minWidth: "300px" }}>
                <Menu.Item key="item-1">
                  <Checkbox
                    checked={settings.enableLabel}
                    onChange={(e) => onChange(e.target.checked, "enableLabel")}
                  >
                    Etiketi Aç/Kapa
                  </Checkbox>
                </Menu.Item>
                <Menu.Item key="item-2">
                  <Checkbox
                    checked={settings.enableGridX}
                    onChange={(e) => onChange(e.target.checked, "enableGridX")}
                  >
                    Dikey Çizgleri Aç/Kapa
                  </Checkbox>
                </Menu.Item>
                <Menu.Item key="item-3">
                  <Checkbox
                    checked={settings.enableGridY}
                    onChange={(e) => onChange(e.target.checked, "enableGridY")}
                  >
                    Yatay Çizgleri Aç/Kapa
                  </Checkbox>
                </Menu.Item>
                <Menu.Item key="item-4" disabled>
                  <SettingSlider
                    label="Etiket Görünme Limiti"
                    min={0}
                    max={36}
                    step={1}
                    name="labelSkipHeight"
                    value={settings.labelSkipHeight}
                    onChange={onChange}
                  />
                </Menu.Item>
              </Menu>
            }
          >
            <CrudPageProcessButton
              tooltipText={"Grafik Ayarı"}
              icon={<SettingOutlined />}
              onClick={undefined}
            />
          </Dropdown>
        </CenteredCol>
      </Row>
      {data.length > 0 &&
        (loading ? (
          <Row style={{ paddingTop: "5em", paddingBottom: "5em" }}>
            <CenteredCol span={24}>
              <SyncOutlined spin />
            </CenteredCol>
          </Row>
        ) : (
          <div
            style={{
              width: "100%",
              height: "500px",
              maxHeight: "500px",
              backgroundColor: "white",
            }}
          >
            <ResponsiveBar
              data={data}
              keys={["Maliyet", "Kazanç", "Kdv"]}
              indexBy="category"
              margin={settings.margin}
              labelSkipHeight={settings.labelSkipHeight}
              enableGridX={settings.enableGridX}
              enableGridY={settings.enableGridY}
              enableLabel={settings.enableLabel}
              valueFormat={(value) => value + "₺"}
              axisBottom={{
                legend: "Kategoriler",
                legendPosition: "middle",
                legendOffset: settings.bottomOffset,
              }}
              axisLeft={{
                legend: "Kazanç",
                legendPosition: "middle",
                legendOffset: settings.leftOffset,
              }}
            />
          </div>
        ))}
    </div>
  );
};
export default BarChart;
