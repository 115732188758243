import { Tooltip, Button } from "antd";
import { ReactElement } from "react";

interface ICrudPageProcessButtonProps {
  tooltipText: string;
  icon: ReactElement;
  onClick: any;
  disabled?: boolean;
}

const CrudPageProcessButton = (props: ICrudPageProcessButtonProps) => {
  return (
    <Tooltip title={props.tooltipText}>
      <Button
        disabled={props.disabled}
        style={{ marginRight: "1em" }}
        onClick={props.onClick}
        shape="circle"
        icon={props.icon}
      />
    </Tooltip>
  );
};

export default CrudPageProcessButton;
