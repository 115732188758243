import { Navigate, Outlet } from "react-router-dom";
import { AuthHelper } from "../helper/AuthHelper";

interface PrivateRouteProps {
  loginPath?: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  return AuthHelper.isLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to={props.loginPath ?? "/login"} />
  );
};

export default PrivateRoute;
