import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  otherApi,
  accountCrudApi,
  accountItemCrudApi,
  accountCrudExtendedApi,
} from "../../client/EntityApi";
import CrudPageV2 from "../crud/CrudPageV2";
import {
  getAccountItemCrudPageColumns,
  getAccountItemCrudPageFormItems,
} from "../../components/crud/AccountItemCrudPageComponents";

const AccountItemCrudPage = () => {
  const { id } = useParams();
  const [types, setTypes] = useState([]);
  const [summary, setSummary] = useState(0);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [accountName, setAccountName] = useState<string>("");

  const getAccItems = () => {
    return accountCrudExtendedApi.getAccItemsWithAccId(id);
  };

  const updateSummary = () => {
    if (id) {
      setSummaryLoading(true);
      accountCrudApi
        .get(parseInt(id))
        .then((response) => {
          setAccountName(response.name);
          setSummary(response.summaryAmount);
        })
        .finally(() => setSummaryLoading(false));
    }
  };

  useEffect(() => {
    otherApi.constants().then((response) => {
      setTypes(response["processTypes"]);
    });
  }, []);

  return (
    <>
      <h3 style={{ marginRight: "1em" }}>
        <b>
          {summaryLoading
            ? "Yükleniyor.."
            : accountName + " Cari Özeti : " + summary.toFixed(2) + " TL"}
        </b>
      </h3>
      <CrudPageV2
        getAll={getAccItems}
        api={accountItemCrudApi}
        entityLabel="Cari Kayıt"
        searchKey="name"
        columns={getAccountItemCrudPageColumns(types)}
        addForm={getAccountItemCrudPageFormItems(types, "api/accounts/" + id)}
        editForm={getAccountItemCrudPageFormItems(types, "api/accounts/" + id)}
        afterRefreshOperation={updateSummary}
        setEditFields={(row) => {
          return {
            name: row.name,
            amount: row.amount,
            type: row.type,
          };
        }}
      />
    </>
  );
};

export default AccountItemCrudPage;
