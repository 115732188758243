import { Checkbox, Form, Input, Select, Tag } from "antd";
import { Util } from "../../helper/Util";

export const getAccountCrudPageColumns = (accountTypes: any) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Cari Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cari Tipi",
      dataIndex: "type",
      key: "type",
      render: (value: any) => <>{Util.getConstantValue(value, accountTypes)}</>,
    },
    {
      title: "Cari Özeti",
      dataIndex: "summaryAmount",
      key: "summaryAmount",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Aktif Mi?",
      key: "isActive",
      dataIndex: "isActive",
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>{value ? "Aktif" : "Pasif"}</Tag>
      ),
      defaultFilteredValue: [true],
      filters: [
        {
          text: "Aktif",
          value: true,
        },
        {
          text: "Pasif",
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isActive === value,
    },
  ];
};

export const getAccountCrudPageFormItems = (accountTypes: any) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Cari Adı"
        name="name"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Cari Tipi"
        name="type"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select options={accountTypes} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Aktif Mi?"
        name="isActive"
        initialValue={true}
        valuePropName="checked"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};
