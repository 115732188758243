import api from "./AxiosConfig";

export const getCrudApi = (entityName: string) => {
  const url = "api/" + entityName;
  const urlWithSlash = url + "/";

  return {
    get: (id: number) => {
      return api.get(urlWithSlash + id).then((response: any) => {
        return response.data;
      });
    },
    getAll: () => {
      return api.get(url).then((response: any) => {
        return response.data;
      });
    },
    create: (entity: any) => {
      return api.post(url, entity).then((response: any) => {
        return response.data;
      });
    },
    edit: (id: number, entity: any) => {
      return api.put(urlWithSlash + id, entity).then((response: any) => {
        return response.data;
      });
    },
    delete: (id: number) => {
      return api.delete(urlWithSlash + id).then((response: any) => {
        return response.data;
      });
    },
  };
};
