import { Form, Input, Select } from "antd";
import { cellStyle } from "../../helper/Util";

export const getInvoiceCrudPageExpanded = (record: any) => {
  return (
    <>
      {record.workOrders.map((workOrder: any, index: any) => {
        return (
          <table key={index} style={{ minWidth: "100%", marginBottom: "1em" }}>
            <tbody>
              <tr>
                <th colSpan={8} style={cellStyle}>
                  {workOrder.name}
                </th>
              </tr>
              <tr>
                <th style={cellStyle}>Ürün Adı</th>
                <th style={cellStyle}>Birim</th>
                <th style={cellStyle}>Miktar</th>
                <th style={cellStyle}>Birim Fiyatı</th>
                <th style={cellStyle}>Maliyet</th>
                <th style={cellStyle}>Tutar</th>
                <th style={cellStyle}>Kdv Oranı</th>
                <th style={cellStyle}>Fatura Fiyatı</th>
              </tr>
              {workOrder.workOrderItems.map(
                (workOrderItem: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td style={cellStyle}>{workOrderItem.name}</td>
                      <td style={cellStyle}>{workOrderItem.unitName}</td>
                      <td style={cellStyle}>{workOrderItem.quantity}</td>
                      <td style={cellStyle}>{workOrderItem.unitPrice}</td>
                      <td style={cellStyle}>{workOrderItem.cost.toFixed(2)}</td>
                      <td style={cellStyle}>
                        {workOrderItem.gainCost.toFixed(2)}
                      </td>
                      <td style={cellStyle}>{"%" + workOrderItem.kdv}</td>
                      <td style={cellStyle}>
                        {workOrderItem.invoicePrice.toFixed(2)}
                      </td>
                    </tr>
                  );
                }
              )}
              <tr>
                <th style={cellStyle} colSpan={4}></th>
                <th style={cellStyle}>{workOrder.cost.toFixed(2)}</th>
                <th style={cellStyle}>{workOrder.gainCost.toFixed(2)}</th>
                <th style={cellStyle}></th>
                <th style={cellStyle}>{workOrder.invoicePrice.toFixed(2)}</th>
              </tr>
            </tbody>
          </table>
        );
      })}
    </>
  );
};

export const getInvoiceCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Fatura Adı",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cari",
      dataIndex: "accountItem",
      key: "accountItem",
      render: (value: any) => <>{value?.account?.name}</>,
    },
    {
      title: "Fatura Tutarı",
      dataIndex: "invoicePrice",
      key: "invoicePrice",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Kesinti Tutarı",
      dataIndex: "cutPrice",
      key: "cutPrice",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Kalan Tutar",
      dataIndex: "remainderPrice",
      key: "remainderPrice",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
  ];
};

export const getInvoiceCrudPageFormItems = (accounts: any, workOrders: any) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Fatura Adı"
        name="name"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Cari"
        name="account"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select options={accounts} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="İş Emri"
        name="workOrders"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select
          allowClear
          mode="multiple"
          options={workOrders}
          filterOption={(input, option: any) =>
            option.label.toLocaleLowerCase().includes(input.toLocaleLowerCase())
          }
        />
      </Form.Item>
    </>
  );
};
