import { Button, Card, Input } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { noteCrudApi } from "../client/EntityApi";

const NotesPage = () => {
  const [notes, setNotes] = useState([]);
  const [description, setDescription] = useState("");
  const [notesLoading, setNotesLoading] = useState(false);
  const [isReadedFilter, setIsReadedFilter] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => getNotes(), []);

  const getNotes = () => {
    setNotesLoading(true);
    noteCrudApi
      .getAll()
      .then((response) => setNotes(response["hydra:member"]))
      .finally(() => setNotesLoading(false));
  };

  return (
    <>
      <Input.Group compact>
        <Input
          style={{ width: "calc(100% - 80px)" }}
          value={description}
          onChange={(e: any) => setDescription(e.target.value)}
        />
        <Button
          type="primary"
          onClick={() => {
            if (!(description.length > 0 && description.length < 5000)) {
              setError(true);
            } else {
              setError(false);
              noteCrudApi
                .create({ description: description })
                .then(() => getNotes())
                .finally(() => setDescription(""));
            }
          }}
        >
          Kayıt Et
        </Button>
        <span hidden={!error} style={{ color: "red" }}>
          Not boş bırakılamaz ve en fazla 5000 karakter olabilir.
        </span>
      </Input.Group>
      <Button
        type="primary"
        style={{ width: "100%", marginTop: "5px" }}
        onClick={() => setIsReadedFilter(!isReadedFilter)}
      >
        {isReadedFilter ? "Okunmamışları Göster" : "Okunmuşları Göster"}
      </Button>
      <div style={{ maxHeight: "60vh", overflow: "auto" }}>
        {notesLoading ? (
          <>Notlar Çekiliyor...</>
        ) : (
          notes
            .filter((note: any) => note.isReaded === isReadedFilter)
            .map((note: any, index: any) => {
              return (
                <Card
                  key={index}
                  title={dayjs(note.createdAt).format("DD/MM/YYYY")}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  extra={
                    <>
                      <Button
                        type="link"
                        style={{ marginRight: "1em" }}
                        onClick={(e) => {
                          e.preventDefault();
                          noteCrudApi
                            .edit(note.id, { isReaded: !note.isReaded })
                            .finally(() => getNotes());
                        }}
                      >
                        {note.isReaded ? "Okunmadı Yap" : "Okundu Yap"}
                      </Button>
                      <Button
                        type="link"
                        onClick={(e) => {
                          e.preventDefault();
                          noteCrudApi.delete(note.id).finally(() => getNotes());
                        }}
                      >
                        Sil
                      </Button>
                    </>
                  }
                >
                  {note.description}
                </Card>
              );
            })
        )}
      </div>
    </>
  );
};

export default NotesPage;
