import { ledgerCrudApi } from "../../client/EntityApi";
import {
  getLedgerCrudPageColumns,
  getLedgerCrudPageFormItems,
} from "../../components/crud/LedgerCrudPageComponents";
import CrudPageV2 from "./CrudPageV2";

const LedgerCrudPage = () => {
  const formItems = getLedgerCrudPageFormItems();
  return (
    <CrudPageV2
      getAll={ledgerCrudApi.getAll}
      api={ledgerCrudApi}
      entityLabel="Hesap"
      searchKey="accountName"
      columns={getLedgerCrudPageColumns()}
      addForm={formItems}
      editForm={formItems}
      setEditFields={(row) => {
        return {
          accountName: row.accountName,
          debt: row.debt,
          paid: row.paid,
        };
      }}
    />
  );
};

export default LedgerCrudPage;
