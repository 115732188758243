import { Slider } from "antd";
import React from "react";

interface ISettingSliderProps {
  label: string;
  min: number;
  max: number;
  step: number;
  name: string;
  value: number;
  onChange: (value: number, name: string) => void;
}

const SettingSlider = (props: ISettingSliderProps) => {
  return (
    <>
      <>{props.label}</>
      <Slider
        min={props.min}
        max={props.max}
        step={props.step}
        marks={{ [props.min]: props.min, [props.max]: props.max }}
        value={props.value}
        onChange={(value) => props.onChange(value, props.name)}
      />
    </>
  );
};

export default SettingSlider;
