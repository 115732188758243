import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { cellStyle } from "../../helper/Util";
import dayjs from "dayjs";

export const getWOCrudPageColumns = (
  pageType = "listPage",
  categories = []
) => {
  let columns: object[] = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İş Emri",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Cari",
      dataIndex: "accountItem",
      key: "accountItem",
      render: (value: any) => <>{value?.account.name}</>,
    },
    {
      title: "Kâr",
      dataIndex: "gain",
      key: "gain",
      render: (value: any) => <>{"%" + value}</>,
    },
    {
      title: "Maliyet",
      dataIndex: "cost",
      key: "cost",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Tutar",
      dataIndex: "gainCost",
      key: "gainCost",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Fatura",
      dataIndex: "invoicePrice",
      key: "invoicePrice",
      render: (value: any) => <>{value.toFixed(2)}</>,
    },
    {
      title: "Oluşturulma",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    },
    {
      title: "Güncellenme",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (value: any) => dayjs(value).format("DD MMM YYYY"),
    },
  ];
  if (pageType === "crudlist") {
    columns.splice(4, 0, {
      title: "Komisyon",
      dataIndex: "comission",
      key: "comission",
      render: (value: any) => <>{"%" + value}</>,
    });
  }

  columns.splice(3, 0, getCategoryColumn(pageType, categories));
  const dateColumns = getApproveAndInvoiceDateColumns(pageType, categories);
  columns.splice(9, 0, dateColumns[0]);
  columns.splice(10, 0, dateColumns[1]);

  return columns;
};

const getApproveAndInvoiceDateColumns = (pageType: string, categories: any) => {
  return pageType === "crudlist"
    ? [
        {
          title: "Onay T.",
          dataIndex: "approvedDate",
          key: "approvedDate",
          render: (value: any) => (
            <Tag color={value ? "green" : "red"}>
              {value ? dayjs(value).format("DD MMM YYYY") : "X"}
            </Tag>
          ),
          filters: [
            {
              text: "Onaylılar",
              value: true,
            },
            {
              text: "Onaysızlar",
              value: false,
            },
          ],
          onFilter: (value: any, record: any) =>
            (record.approvedDate === null) !== value,
        },
        {
          title: "Fatura T.",
          dataIndex: "invoicedDate",
          key: "invoicedDate",
          render: (value: any) => (
            <Tag color={value ? "green" : "red"}>
              {value ? dayjs(value).format("DD MMM YYYY") : "X"}
            </Tag>
          ),
          filters: [
            {
              text: "Faturalılar",
              value: true,
            },
            {
              text: "Faturasızlar",
              value: false,
            },
          ],
          onFilter: (value: any, record: any) =>
            (record.invoicedDate === null) !== value,
        },
      ]
    : [
        {
          title: "Onay T.",
          dataIndex: "approvedDate",
          key: "approvedDate",
          render: (value: any) => (
            <Tag color={value ? "green" : "red"}>
              {value ? dayjs(value).format("DD MMM YYYY") : "X"}
            </Tag>
          ),
        },
        {
          title: "Fatura T.",
          dataIndex: "invoicedDate",
          key: "invoicedDate",
          render: (value: any) => (
            <Tag color={value ? "green" : "red"}>
              {value ? dayjs(value).format("DD MMM YYYY") : "X"}
            </Tag>
          ),
        },
      ];
};

const getCategoryColumn = (pageType: string, categories: any) => {
  const mappedCategories = categories.map((category: any) => {
    category.text = category.label;
    return category;
  });
  return pageType === "crudlist"
    ? {
        title: "Kategori",
        dataIndex: "category",
        key: "category",
        render: (value: any) => <>{value.name}</>,
        filters: mappedCategories,
        onFilter: (value: any, record: any) => record.category["@id"] === value,
      }
    : {
        title: "Kategori",
        dataIndex: "category",
        key: "category",
        render: (value: any) => <>{value.name}</>,
      };
};

export const getWOCrudPageFormItems = (
  categories: any,
  products: any,
  accounts: any,
  addProduct: () => void,
  addProductName: string,
  setAddProductName: (name: string) => void
) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="İş Emri"
        name="name"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kategori"
        name="category"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select options={categories} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Cari"
        name="account"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select options={accounts} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Komisyon Oranı"
        name="comission"
        initialValue="10"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kâr Oranı"
        name="gain"
        initialValue="35"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        label="Kâr Paylaşıldı mı ?"
        name="isGainDistrubuted"
        initialValue="false"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select
          style={{ width: "100%" }}
          options={[
            { label: "Paylaşıldı", value: "true" },
            { label: "Paylaşılmadı", value: "false" },
          ]}
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Onay Tarihi"
        name="approvedDate"
      >
        <DatePicker placeholder="Tarih Seçiniz" format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Fatura Tarihi"
        name="invoicedDate"
      >
        <DatePicker placeholder="Tarih Seçiniz" format="DD/MM/YYYY" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Başlık"
        name="sn"
        initialValue="Sn . Çukurova İlçe Belediye Başkanlığı"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Teslim Eden"
        name="deliverer"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Teslim Alan"
        name="receiver"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Birim"
        name="department"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Plaka"
        name="numberPlate"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Km"
        name="km"
        rules={[{ max: 255, message: "En fazla 255 karakter olabilir!" }]}
      >
        <Input />
      </Form.Item>
      <Form.List name="workOrderItems">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }, index) => (
              <Row
                key={index}
                style={{
                  border: "1px dashed black",
                  margin: "1em 0px ",
                  padding: "1em 0px 0px 1em",
                }}
              >
                <Col span={24}>
                  <Row style={{ marginBottom: "1em" }}>
                    <Col span={20}>
                      <Row justify="center">Ürün Bilgileri</Row>
                    </Col>
                    <Col span={4}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  <>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      label="Ürün Adı"
                      name={[name, "name"]}
                      rules={[{ required: true, message: "Boş bırakılamaz!" }]}
                    >
                      <Select
                        showSearch
                        options={products}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                              <Input
                                placeholder="Yeni Ürün Giriniz"
                                value={addProductName}
                                onChange={(event) =>
                                  setAddProductName(event.target.value)
                                }
                              />
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={addProduct}
                              >
                                Ürün Ekle
                              </Button>
                            </Space>
                          </>
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      label="Miktar"
                      name={[name, "quantity"]}
                      rules={[{ required: true, message: "Boş bırakılamaz!" }]}
                    >
                      <InputNumber precision={2} min={0} stringMode />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      label="Birim Fiyatı"
                      name={[name, "unitPrice"]}
                      rules={[{ required: true, message: "Boş bırakılamaz!" }]}
                    >
                      <InputNumber precision={2} min={0} stringMode />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      label="Kdv"
                      name={[name, "kdv"]}
                      rules={[{ required: true, message: "Boş bırakılamaz!" }]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                      label="Birim"
                      name={[name, "unitName"]}
                      rules={[
                        { required: true, message: "Boş bırakılamaz!" },
                        {
                          max: 255,
                          message: "En fazla 255 karakter olabilir!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                </Col>
              </Row>
            ))}
            <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    kdv: 18,
                    unitPrice: "0",
                    quantity: "1",
                    unitName: "Adet",
                  })
                }
                block
                icon={<PlusOutlined />}
              >
                Ürün Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};

export const getWOCrudPageExpanded = (record: any) => {
  return (
    <>
      <table style={{ minWidth: "100%", marginBottom: "1em" }}>
        <tbody>
          <tr>
            <th style={cellStyle}>Ürün Adı</th>
            <th style={cellStyle}>Birim</th>
            <th style={cellStyle}>Miktar</th>
            {/* Birim fiyatı +%10 */}
            <th style={cellStyle}>Birim Fiyatı</th>
            {/* Birim fiyatı * Miktar */}
            <th style={cellStyle}>Maliyet</th>
            {/* Maliyet +%gain */}
            <th style={cellStyle}>Tutar</th>
            <th style={cellStyle}>Kdv Oranı</th>
            {/* Tutar +%kdv */}
            <th style={cellStyle}>Fatura Fiyatı</th>
          </tr>
          {record.workOrderItems.map((workOrderItem: any, index: any) => {
            return (
              <tr key={index}>
                <td style={cellStyle}>{workOrderItem.name}</td>
                <td style={cellStyle}>{workOrderItem.unitName}</td>
                <td style={cellStyle}>{workOrderItem.quantity}</td>
                <td style={cellStyle}>
                  {typeof workOrderItem.unitPrice === "string"
                    ? workOrderItem.unitPrice
                    : workOrderItem.unitPrice.toFixed(2)}
                </td>
                <td style={cellStyle}>{workOrderItem.cost.toFixed(2)}</td>
                <td style={cellStyle}>{workOrderItem.gainCost.toFixed(2)}</td>
                <td style={cellStyle}>{"%" + workOrderItem.kdv}</td>
                <td style={cellStyle}>
                  {workOrderItem.invoicePrice.toFixed(2)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {record.sn && (
        <>
          <b>Başlık: </b> {record.sn} <br></br>
        </>
      )}
      {record.deliverer && (
        <>
          <b>Teslim Eden: </b> {record.deliverer} <br></br>
        </>
      )}
      {record.receiver && (
        <>
          <b>Teslim Alan: </b> {record.receiver} <br></br>
        </>
      )}
      {record.department && (
        <>
          <b>Birim: </b> {record.department} <br></br>
        </>
      )}
      {record.numberPlate && (
        <>
          <b>Plaka: </b> {record.numberPlate} <br></br>
        </>
      )}
      {record.km && (
        <>
          <b>Km: </b> {record.km} <br></br>
        </>
      )}
    </>
  );
};
