import {
  getWOCrudPageColumns,
  getWOCrudPageExpanded,
} from "../../components/crud/WOCrudPageComponents";
import { woCrudApi } from "../../client/EntityApi";
import CrudPageV2 from "./CrudPageV2";

const WOListPage = () => {
  return (
    <CrudPageV2
      getAll={woCrudApi.getAll}
      api={woCrudApi}
      entityLabel="İş Emri"
      searchKey="name"
      columns={getWOCrudPageColumns()}
      addForm={<></>}
      editForm={<></>}
      hideDefaultProceses
      hideDefaultTitleProcess
      expandable={{
        expandedRowRender: (record: any) => getWOCrudPageExpanded(record),
      }}
      setEditFields={() => {}}
    />
  );
};

export default WOListPage;
