import { Form, Input, InputNumber } from "antd";

export const getLedgerCrudPageColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Cari Adı",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Borç",
      dataIndex: "debt",
      key: "debt",
    },
    {
      title: "Ödenen",
      dataIndex: "paid",
      key: "paid",
    },
    {
      title: "Kalan",
      key: "total",
      render: (_value: any, row: any) => (
        <>{(row.debt - row.paid).toFixed(2)}</>
      ),
    },
  ];
};

export const getLedgerCrudPageFormItems = () => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Cari Adı"
        name="accountName"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Borç"
        name="debt"
        initialValue="0"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Ödenen"
        name="paid"
        initialValue="0"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <InputNumber precision={2} min={0} stringMode />
      </Form.Item>
    </>
  );
};
