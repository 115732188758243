import { IKeyValue } from "../interfaces/IEntity";
import * as htmlToImage from "html-to-image";

export const cellStyle = {
  border: "1px solid #dddddd",
  textAlign: "center" as "center",
};

export const Util = {
  getConstantValue: (value: any, constants: IKeyValue[]) => {
    const findedConstant = constants.find((constant) => {
      return constant.value === value;
    });

    return findedConstant ? findedConstant.label : "";
  },
  mapToLabelValue: (entityArray: any, name = "name") => {
    return entityArray.map((entity: any) => {
      return {
        label: entity[name],
        value: entity["@id"],
      };
    });
  },
  mapToCustomLabelValue: (entityArray: any, name = "name") => {
    return entityArray.map((entity: any) => {
      return {
        label:
          entity["id"] +
          " | " +
          entity[name] +
          (entity.isInvoice ? " (Faturalanmış)" : ""),
        value: entity["@id"],
      };
    });
  },
  extractIdFromIri: (iri: string, className: string) => {
    return parseInt(iri.replace("/api/" + className + "/", ""));
  },
  download: async (ref: any, name: string) => {
    if (ref.current == null) return;
    const dataUrl = await htmlToImage.toPng(ref.current);
    const link = document.createElement("a");
    link.download = name;
    link.href = dataUrl;
    link.click();
  },
};
