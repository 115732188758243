import { Navigate } from "react-router-dom";
import { AuthHelper } from "../helper/AuthHelper";

interface LoginRouteProps {
  element: any;
  redirectPath?: string;
}

const LoginRoute = (props: LoginRouteProps) => {
  return AuthHelper.isLoggedIn() ? (
    <Navigate to={props.redirectPath ?? "/admin"} />
  ) : (
    props.element
  );
};

export default LoginRoute;
