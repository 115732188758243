import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginRoute from "./components/LoginRoute";
import PrivateRoute from "./components/PrivateRoute";
import SidebarLayout from "./layouts/SidebarLayout";
import LoginPage from "./pages/LoginPage";
import UserCrudPage from "./pages/crud/UserCrudPage";
import CategoryCrudPage from "./pages/crud/CategoryCrudPage";
import { AuthHelper } from "./helper/AuthHelper";
import AccountCrudPage from "./pages/crud/AccountCrudPage";
import AccountItemCrudPage from "./pages/crud/AccountItemCrudPage";
import ProductCrudPage from "./pages/crud/ProductCrudPage";
import WOCrudPage from "./pages/crud/WOCrudPage";
import WOListPage from "./pages/crud/WOListPage";
import InvoiceCrudPage from "./pages/crud/InvoiceCrudPage";
import WOUserListPage from "./pages/crud/WOUserListPage";
import NotesPage from "./pages/NotesPage";
import LedgerCrudPage from "./pages/crud/LedgerCrudPage";
import ProformaHistoryCrudPage from "./pages/crud/ProformaHistoryCrudPage";
import AnalysePage from "./pages/AnalysePage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginRoute element={<LoginPage />} />} />
        <Route path="/admin" element={<PrivateRoute />}>
          <Route index element={<SidebarLayout />} />
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="analiz"
              element={
                <SidebarLayout>
                  <AnalysePage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="proforma"
              element={
                <SidebarLayout>
                  <ProformaHistoryCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="hesap-defteri"
              element={
                <SidebarLayout>
                  <LedgerCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="notlar"
              element={
                <SidebarLayout>
                  <NotesPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="kullanicilar"
              element={
                <SidebarLayout>
                  <UserCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="kategoriler"
              element={
                <SidebarLayout>
                  <CategoryCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="cariler"
              element={
                <SidebarLayout>
                  <AccountCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="cari-detay/:id"
              element={
                <SidebarLayout>
                  <AccountItemCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="urunler"
              element={
                <SidebarLayout>
                  <ProductCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="is-emirleri-yonetim"
              element={
                <SidebarLayout>
                  <WOCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_ADMIN") && (
            <Route
              path="faturalar"
              element={
                <SidebarLayout>
                  <InvoiceCrudPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_AUTO") && (
            <Route
              path="is-emirleri-listesi"
              element={
                <SidebarLayout>
                  <WOListPage />
                </SidebarLayout>
              }
            />
          )}
          {AuthHelper.checkRole("ROLE_USER") && (
            <Route
              path="is-emirleri"
              element={
                <SidebarLayout>
                  <WOUserListPage />
                </SidebarLayout>
              }
            />
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
