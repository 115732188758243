import ReactDOM from "react-dom/client";
import App from "./App";
import "antd/dist/antd.min.css";
import "dayjs/locale/tr";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import tr_TR from "antd/es/locale/tr_TR";
import "moment/locale/tr";

dayjs.locale("tr");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider locale={tr_TR}>
    <App />
  </ConfigProvider>
);
