import {
  getInvoiceCrudPageColumns,
  getInvoiceCrudPageExpanded,
  getInvoiceCrudPageFormItems,
} from "../../components/crud/InvoiceCrudPageComponents";
import {
  accountCrudApi,
  invoiceCrudApi,
  otherApi,
  proformaHistoryCrudApi,
  woCrudApi,
} from "../../client/EntityApi";
import CrudPageV2, { ICrudPageV2RefMethods } from "./CrudPageV2";
import { useEffect, useRef, useState } from "react";
import { Util } from "../../helper/Util";
import CrudPageProcessButton from "../../components/CrudPageProcessButton";
import { FileDoneOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Select } from "antd";

const InvoiceCrudPage = () => {
  const [convertToInvoiceForm] = Form.useForm();
  const crudPageRef = useRef<ICrudPageV2RefMethods>(null);

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [workOrders, setWorkOrders] = useState([]);
  const [invoicedWorkOrders, setInvoicedWorkOrders] = useState([]);
  const [proformaHistories, setProformaHistories] = useState([]);
  const [convertToInvoiceModal, setConvertToInvoiceModal] = useState(false);

  const clearConvertToInvoiceModal = () => {
    setConvertToInvoiceModal(false);
    convertToInvoiceForm.resetFields();
  };

  const updateWOOptions = () => {
    woCrudApi.getAll().then((response: any) => {
      const filteredWO = response["hydra:member"].filter(
        (workOrder: any) =>
          workOrder.isInvoice === false &&
          workOrder.isProformad === false &&
          workOrder.approvedDate !== null
      );
      setInvoicedWorkOrders(Util.mapToCustomLabelValue(filteredWO));
      setWorkOrders(Util.mapToCustomLabelValue(response["hydra:member"]));
    });
  };

  const updateProformaHistories = () => {
    proformaHistoryCrudApi
      .getAll()
      .then((response: any) =>
        setProformaHistories(
          Util.mapToLabelValue(
            response["hydra:member"].filter(
              (proformaHistory: any) => !proformaHistory.isInvoiced
            )
          )
        )
      );
  };

  useEffect(() => {
    accountCrudApi.getAll().then((response: any) => {
      const extendedAccounts = response["hydra:member"].map((account: any) => {
        return {
          ...account,
          name: account.name + (account.isActive ? "" : " (Pasif)"),
        };
      });
      setAccounts(Util.mapToLabelValue(extendedAccounts));

      const filteredAccounts = extendedAccounts.filter(
        (account: any) => account.isActive
      );
      setFilteredAccounts(Util.mapToLabelValue(filteredAccounts));
    });
  }, []);

  return (
    <>
      <CrudPageV2
        ref={crudPageRef}
        getAll={invoiceCrudApi.getAll}
        api={invoiceCrudApi}
        entityLabel="Fatura"
        searchKey="name"
        columns={getInvoiceCrudPageColumns()}
        addForm={getInvoiceCrudPageFormItems(
          filteredAccounts,
          invoicedWorkOrders
        )}
        editForm={getInvoiceCrudPageFormItems(accounts, workOrders)}
        afterRefreshOperation={() => {
          updateWOOptions();
          updateProformaHistories();
        }}
        expandable={{
          expandedRowRender: (record: any) =>
            getInvoiceCrudPageExpanded(record),
        }}
        titleProceses={() => {
          return (
            <CrudPageProcessButton
              tooltipText="Faturaya Dönüştür"
              onClick={() => setConvertToInvoiceModal(true)}
              icon={<FileDoneOutlined />}
            />
          );
        }}
        setEditFields={(row) => {
          return {
            name: row.name,
            account: row.accountItem?.account["@id"],
            workOrders: row.workOrders.map((workOrder: any) => {
              return workOrder["@id"];
            }),
          };
        }}
      />
      <Modal
        visible={convertToInvoiceModal}
        title="Faturaya Dönüştür"
        onCancel={() => clearConvertToInvoiceModal()}
        onOk={() => {
          convertToInvoiceForm.validateFields().then((values) => {
            otherApi
              .convertToInvoice(values)
              .then((response) => {
                alert(response.message);
                crudPageRef.current?.refreshData();
              })
              .finally(() => clearConvertToInvoiceModal());
          });
        }}
        okText="Dönüştür"
        cancelText="Vazgeç"
      >
        <Form form={convertToInvoiceForm}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label="Fatura Adı"
            name="name"
            rules={[
              { required: true, message: "Boş bırakılamaz!" },
              { max: 255, message: "En fazla 255 karakter olabilir!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label="Cari"
            name="account"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Select options={filteredAccounts} />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            label="Proforma"
            name="proformaHistory"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Select options={proformaHistories} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default InvoiceCrudPage;
