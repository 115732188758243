import {
  getUserCrudPageColumns,
  getUserCrudPageFormItems,
} from "../../components/crud/UserCrudPageComponents";
import { categoryCrudApi, otherApi, userCrudApi } from "../../client/EntityApi";
import CrudPageV2 from "./CrudPageV2";
import { useEffect, useState } from "react";
import { Util } from "../../helper/Util";

const UserCrudPage = () => {
  const [roles, setRoles] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    otherApi.constants().then((response: any) => {
      setRoles(response["roles"]);
    });
    categoryCrudApi.getAll().then((response: any) => {
      setCategories(Util.mapToLabelValue(response["hydra:member"]));
    });
  }, []);

  return (
    <CrudPageV2
      getAll={userCrudApi.getAll}
      api={userCrudApi}
      entityLabel="Kullanıcı"
      searchKey="fullName"
      columns={getUserCrudPageColumns(roles)}
      addForm={getUserCrudPageFormItems(roles, categories)}
      editForm={getUserCrudPageFormItems(roles, categories)}
      setEditFields={(row) => {
        return {
          fullName: row.fullName,
          username: row.username,
          isActive: row.isActive,
          roles: row.roles,
          categories: row.categories.map((category: any) => {
            return category["@id"];
          }),
        };
      }}
    />
  );
};

export default UserCrudPage;
