import { ResponsivePie } from "@nivo/pie";
import { Checkbox, DatePicker, Dropdown, Menu, Row } from "antd";
import { useState, useEffect, useRef } from "react";
import { otherApi } from "../../client/EntityApi";
import CrudPageProcessButton from "../CrudPageProcessButton";
import {
  SettingOutlined,
  DownloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import CenteredCol from "../CenteredCol";
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { Util } from "../../helper/Util";
import SettingSlider from "./SettingSlider";

const PieChart = () => {
  const { RangePicker } = DatePicker;
  const chartRef = useRef(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<RangeValue<Moment>>(null);
  const [settings, setSettings] = useState({
    margin: { top: 50, right: 50, bottom: 50, left: 50 },
    innerRadius: 0.4,
    padAngle: 1,
    cornerRadius: 5,
    activeOuterRadiusOffset: 15,
    enableArcLabels: true,
    arcLabelsSkipAngle: 10,
    arcLabelsRadiusOffset: 0.5,
    enableArcLinkLabels: true,
    arcLinkLabelsSkipAngle: 5,
    arcLinkLabelsDiagonalLength: 20,
    arcLinkLabelsStraightLength: 20,
    arcLinkLabelsTextOffset: 10,
    arcLinkLabelsThickness: 2,
  });

  useEffect(() => {
    if (range === null) return;
    setLoading(true);
    otherApi
      .analyseData("pie", range)
      .then((response) => setData(response))
      .finally(() => setLoading(false));
  }, [range]);

  const onChange = (value: any, name: string) => {
    setSettings({ ...settings, [name]: value });
  };

  return (
    <div ref={chartRef} style={{ backgroundColor: "white" }}>
      <Row justify="center" style={{ padding: "1em" }}>
        <CenteredCol span={5}>
          <h3 style={{ color: "#414a4c" }}>
            <b>Kategori Bazlı Kâr Grafiği</b>
          </h3>
        </CenteredCol>
        <CenteredCol offset={10} span={6}>
          <RangePicker format={"DD/MM/YYYY"} onChange={(e) => setRange(e)} />
        </CenteredCol>
        <CenteredCol offset={1} span={1}>
          <CrudPageProcessButton
            tooltipText={"Grafik Çıktısı Al"}
            icon={<DownloadOutlined />}
            onClick={() =>
              Util.download(chartRef, "Kategori Bazlı Kâr Grafiği.png")
            }
          />
        </CenteredCol>
        <CenteredCol span={1}>
          <Dropdown
            disabled={data.length === 0}
            trigger={["click"]}
            overlay={
              <Menu style={{ minWidth: "300px" }}>
                <Menu.Item key="item-1" disabled>
                  <SettingSlider
                    label="İç Çap"
                    min={0}
                    max={0.95}
                    step={0.05}
                    name="innerRadius"
                    value={settings.innerRadius}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-2" disabled>
                  <SettingSlider
                    label="Dilim Boşluğu"
                    min={0}
                    max={45}
                    step={1}
                    name="padAngle"
                    value={settings.padAngle}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-3" disabled>
                  <SettingSlider
                    label="Dilim Ovalliği"
                    min={0}
                    max={45}
                    step={1}
                    name="cornerRadius"
                    value={settings.cornerRadius}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-4" disabled>
                  <Checkbox
                    checked={settings.enableArcLabels}
                    onChange={(e) =>
                      onChange(e.target.checked, "enableArcLabels")
                    }
                  >
                    Dilim Etiketi Aç/Kapa
                  </Checkbox>
                </Menu.Item>
                <Menu.Item key="item-5" disabled>
                  <SettingSlider
                    label="Dilim Etiketinin Konumu"
                    min={0}
                    max={2}
                    step={0.05}
                    name="arcLabelsRadiusOffset"
                    value={settings.arcLabelsRadiusOffset}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-6" disabled>
                  <SettingSlider
                    label="Dilim Etiketinin Görünme Limiti"
                    min={0}
                    max={45}
                    step={1}
                    name="arcLabelsSkipAngle"
                    value={settings.arcLabelsSkipAngle}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-7" disabled>
                  <Checkbox
                    checked={settings.enableArcLinkLabels}
                    onChange={(e) =>
                      onChange(e.target.checked, "enableArcLinkLabels")
                    }
                  >
                    Çubuk Etiketi Aç/Kapa
                  </Checkbox>
                </Menu.Item>
                <Menu.Item key="item-8" disabled>
                  <SettingSlider
                    label="Çubuk Etiket Görünme Limiti"
                    min={0}
                    max={45}
                    step={1}
                    name="arcLinkLabelsSkipAngle"
                    value={settings.arcLinkLabelsSkipAngle}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-9" disabled>
                  <SettingSlider
                    label="Çubuk Etiket Dikey Uzaklık"
                    min={0}
                    max={36}
                    step={1}
                    name="arcLinkLabelsDiagonalLength"
                    value={settings.arcLinkLabelsDiagonalLength}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-10" disabled>
                  <SettingSlider
                    label="Çubuk Etiket Yatay Uzaklık"
                    min={0}
                    max={36}
                    step={1}
                    name="arcLinkLabelsStraightLength"
                    value={settings.arcLinkLabelsStraightLength}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-11" disabled>
                  <SettingSlider
                    label="Çubuk Etiket Çubuk-Yazı Uzaklığı"
                    min={0}
                    max={36}
                    step={1}
                    name="arcLinkLabelsTextOffset"
                    value={settings.arcLinkLabelsTextOffset}
                    onChange={onChange}
                  />
                </Menu.Item>
                <Menu.Item key="item-12" disabled>
                  <SettingSlider
                    label="Çubuk Kalınlığı"
                    min={0}
                    max={20}
                    step={1}
                    name="arcLinkLabelsThickness"
                    value={settings.arcLinkLabelsThickness}
                    onChange={onChange}
                  />
                </Menu.Item>
              </Menu>
            }
          >
            <CrudPageProcessButton
              tooltipText={"Grafik Ayarı"}
              icon={<SettingOutlined />}
              onClick={undefined}
            />
          </Dropdown>
        </CenteredCol>
      </Row>
      {data.length > 0 &&
        (loading ? (
          <Row style={{ paddingTop: "5em", paddingBottom: "5em" }}>
            <CenteredCol span={24}>
              <SyncOutlined spin />
            </CenteredCol>
          </Row>
        ) : (
          <div
            style={{
              width: "100%",
              height: "500px",
              maxHeight: "500px",
              backgroundColor: "white",
            }}
          >
            <ResponsivePie
              data={data}
              colors={{ datum: "data.color" }}
              margin={settings.margin}
              enableArcLinkLabels={settings.enableArcLinkLabels}
              innerRadius={settings.innerRadius}
              padAngle={settings.padAngle}
              cornerRadius={settings.cornerRadius}
              activeOuterRadiusOffset={settings.activeOuterRadiusOffset}
              arcLabelsRadiusOffset={settings.arcLabelsRadiusOffset}
              enableArcLabels={settings.enableArcLabels}
              arcLabelsSkipAngle={settings.arcLabelsSkipAngle}
              arcLinkLabelsSkipAngle={settings.arcLinkLabelsSkipAngle}
              arcLinkLabelsDiagonalLength={settings.arcLinkLabelsDiagonalLength}
              arcLinkLabelsStraightLength={settings.arcLinkLabelsStraightLength}
              arcLinkLabelsTextOffset={settings.arcLinkLabelsTextOffset}
              arcLinkLabelsThickness={settings.arcLinkLabelsThickness}
              sortByValue={true}
              valueFormat={(value) => value + "₺"}
              arcLabel={(datum: any) => datum.data.percent + "%"}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              legends={[
                {
                  data: data.map((item) => {
                    const label =
                      item.label +
                      " (" +
                      item.percent +
                      "%) (" +
                      item.value +
                      "₺)";
                    return { ...item, label: label };
                  }),
                  anchor: "top-right",
                  direction: "column",
                  itemDirection: "right-to-left",
                  justify: false,
                  itemWidth: 127,
                  itemHeight: 40,
                  itemTextColor: "#999",
                  symbolSize: 14,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        ))}
    </div>
  );
};

export default PieChart;
