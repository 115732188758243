import { Row, Col, Divider, Form, Input, Button } from "antd";
import { AuthHelper } from "../helper/AuthHelper";

const LoginPage = () => {
  return (
    <Row
      align="middle"
      style={{
        height: "100vh",
        backgroundImage: "linear-gradient(#7598e7, #c533dd)",
      }}
    >
      <Col
        offset={8}
        span={8}
        style={{
          backgroundColor: "white",
          minHeight: "30vh",
          borderRadius: "5px",
          padding: "2em",
        }}
      >
        <Row align="middle" justify="center">
          <h4>{process.env.REACT_APP_PROJECT_NAME}</h4>
        </Row>
        <Divider />
        <Form
          layout="vertical"
          onFinish={(values: any) =>
            AuthHelper.login(values.username, values.password)
          }
        >
          <Form.Item
            label="Kullanıcı Adı"
            name="username"
            rules={[
              { required: true, message: "Kullanıcı adı boş bırakılamaz." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Şifre"
            name="password"
            rules={[{ required: true, message: "Şifre boş bırakılamaz." }]}
          >
            <Input.Password />
          </Form.Item>
          <Divider />
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Giriş Yap
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
