import BarChart from "../components/chart/BarChart";
import PieChart from "../components/chart/PieChart";

const AnalysePage = () => {
  return (
    <>
      <BarChart />
      <br />
      <PieChart />
    </>
  );
};

export default AnalysePage;
