import {
  getCategoryCrudPageColumns,
  getCategoryCrudPageFormItems,
} from "../../components/crud/CategoryCrudPageComponents";
import { categoryCrudApi } from "../../client/EntityApi";
import CrudPageV2 from "./CrudPageV2";

const CategoryCrudPage = () => {
  return (
    <CrudPageV2
      getAll={categoryCrudApi.getAll}
      api={categoryCrudApi}
      entityLabel="Kategori"
      searchKey="name"
      columns={getCategoryCrudPageColumns()}
      addForm={getCategoryCrudPageFormItems()}
      editForm={getCategoryCrudPageFormItems()}
      setEditFields={(row) => {
        return {
          name: row.name,
          color: row.color,
        };
      }}
    />
  );
};

export default CategoryCrudPage;
