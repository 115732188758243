import {
  getWOUserCrudPageColumns,
  getWOUserCrudPageExpanded,
} from "../../components/crud/WOUserCrudPageComponents";
import { woCrudExtendedApi } from "../../client/EntityApi";
import CrudPageV2 from "./CrudPageV2";

const WOUserListPage = () => {
  const getWorkOrders = () => {
    return woCrudExtendedApi.getRoleUserWorkOrders();
  };

  return (
    <CrudPageV2
      getAll={getWorkOrders}
      key="id"
      api={{}}
      entityLabel="İş Emri"
      searchKey="name"
      columns={getWOUserCrudPageColumns()}
      addForm={<></>}
      editForm={<></>}
      hideDefaultProceses
      hideDefaultTitleProcess
      expandable={{
        expandedRowRender: (record: any) => getWOUserCrudPageExpanded(record),
      }}
      setEditFields={() => {}}
    />
  );
};

export default WOUserListPage;
