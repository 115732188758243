import {
  getWOCrudPageColumns,
  getWOCrudPageFormItems,
  getWOCrudPageExpanded,
} from "../../components/crud/WOCrudPageComponents";
import {
  accountCrudApi,
  categoryCrudApi,
  otherApi,
  productCrudApi,
  woCrudApi,
} from "../../client/EntityApi";
import CrudPageV2, { ICrudPageV2RefMethods } from "./CrudPageV2";
import { useEffect, useRef, useState } from "react";
import { cellStyle, Util } from "../../helper/Util";
import moment from "moment";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import {
  DownloadOutlined,
  CalculatorOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const WOCrudPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [calculateGainModal, setCalculateGainModal] = useState(false);
  const [calculateGainLoading, setCalculateGainLoading] = useState(false);
  const [openedWOLoading, setOpenedWOLoading] = useState(false);
  const [openedWOModal, setOpenedWOModal] = useState(false);
  const [openedWOs, setOpenedWOs] = useState([]);
  const [offerWOModal, setOfferWOModal] = useState(false);
  const [mappedWOs, setMappedWOs] = useState([]);
  const [summaryGain, setSummaryGain] = useState(0);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState();
  const [addProductName, setAddProductName] = useState<string>("");
  const crudPageRef = useRef<ICrudPageV2RefMethods>(null);

  const [form] = Form.useForm();
  const [opendWOForm] = Form.useForm();
  const [offerWOForm] = Form.useForm();

  useEffect(() => {
    categoryCrudApi.getAll().then((response) => {
      setCategories(Util.mapToLabelValue(response["hydra:member"]));
    });
    productCrudApi.getAll().then((response) => {
      setProducts(
        response["hydra:member"].map((product: any) => {
          return {
            label: product.name,
            value: product.name,
          };
        })
      );
    });
    accountCrudApi.getAll().then((response: any) => {
      const extendedAccounts = response["hydra:member"].map((account: any) => {
        return {
          ...account,
          name: account.name + (account.isActive ? "" : " (Pasif)"),
        };
      });
      setAccounts(Util.mapToLabelValue(extendedAccounts));

      const filteredAccounts = extendedAccounts.filter(
        (account: any) => account.isActive
      );
      setFilteredAccounts(Util.mapToLabelValue(filteredAccounts));
    });
  }, []);

  const addProduct = () => {
    productCrudApi.create({ name: addProductName }).then((response) => {
      setProducts([
        ...products,
        { label: response.name, value: response.name } as never,
      ]);
    });
  };

  const clearCalculateModal = () => {
    setSelectedWorkOrder(undefined);
    setSummaryGain(0);
    form.resetFields();
    setCalculateGainLoading(false);
    setCalculateGainModal(false);
  };

  const clearOpenedWOModal = () => {
    opendWOForm.resetFields();
    setOpenedWOModal(false);
    setOpenedWOLoading(false);
    setOpenedWOs([]);
  };

  const clearOfferWOModal = () => {
    offerWOForm.resetFields();
    setOfferWOModal(false);
  };

  return (
    <>
      <CrudPageV2
        ref={crudPageRef}
        getAll={woCrudApi.getAll}
        api={woCrudApi}
        entityLabel="İş Emri"
        searchKey="name"
        columns={getWOCrudPageColumns("crudlist", categories)}
        addForm={getWOCrudPageFormItems(
          categories,
          products,
          filteredAccounts,
          addProduct,
          addProductName,
          setAddProductName
        )}
        editForm={getWOCrudPageFormItems(
          categories,
          products,
          accounts,
          addProduct,
          addProductName,
          setAddProductName
        )}
        titleProceses={() => {
          return (
            <>
              <Tooltip title={"Açık İş Emirleri"}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={() => setOpenedWOModal(true)}
                  shape="circle"
                  icon={<FolderOpenOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Proforma Oluştur"}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={() => setOfferWOModal(true)}
                  shape="circle"
                  icon={<FileUnknownOutlined />}
                />
              </Tooltip>
            </>
          );
        }}
        proceses={(row: any) => {
          return (
            <>
              <Tooltip title={"Kâr Dağılımı"}>
                <Button
                  disabled={row.isGainDistrubuted}
                  style={{ marginRight: "1em" }}
                  onClick={() => {
                    setCalculateGainLoading(true);
                    setCalculateGainModal(true);
                    setSelectedWorkOrder(row.id);
                    otherApi
                      .calculateGain(row.id)
                      .then((response: any) => {
                        form.setFieldsValue({ gains: response.gains });
                        setSummaryGain(response.summaryGain);
                      })
                      .finally(() => setCalculateGainLoading(false));
                  }}
                  shape="circle"
                  icon={<CalculatorOutlined />}
                />
              </Tooltip>
              <Tooltip title={"Excel İndir"}>
                <Button
                  style={{ marginRight: "1em" }}
                  onClick={() => {
                    otherApi.exportExcel(row.id).then((response) => {
                      window.open("/" + response.path, "_blank");
                    });
                  }}
                  shape="circle"
                  icon={<DownloadOutlined />}
                />
              </Tooltip>
            </>
          );
        }}
        expandable={{
          expandedRowRender: (record: any) => getWOCrudPageExpanded(record),
        }}
        beforeAddManipulationOnValues={(values) => {
          values.isGainDistrubuted =
            values.isGainDistrubuted === "true" ? true : false;
          return values;
        }}
        beforeEditManipulationOnValues={(values) => {
          values.isGainDistrubuted =
            values.isGainDistrubuted === "true" ? true : false;
          return values;
        }}
        setEditFields={(row) => {
          return {
            name: row.name,
            category: row.category["@id"],
            account: row.accountItem.account["@id"],
            gain: row.gain,
            isGainDistrubuted: row.isGainDistrubuted ? "true" : "false",
            comission: row.comission,
            description: row.description,
            sn: row.sn,
            deliverer: row.deliverer,
            receiver: row.receiver,
            department: row.department,
            numberPlate: row.numberPlate,
            km: row.km,
            approvedDate:
              row.approvedDate === null
                ? null
                : moment(new Date(row.approvedDate)),
            invoicedDate:
              row.invoicedDate === null
                ? null
                : moment(new Date(row.invoicedDate)),
            workOrderItems: row.workOrderItems,
          };
        }}
        afterRefreshOperation={(wos: any) => {
          const mappedWos = wos
            .filter(
              (workOrder: any) =>
                workOrder.isInvoice === false &&
                workOrder.isProformad === false &&
                workOrder.approvedDate !== null
            )
            .map((wo: any) => {
              return {
                label: wo["id"] + " - " + wo["name"],
                value: wo["id"],
              };
            });
          setMappedWOs(mappedWos);
        }}
      />
      <Modal
        visible={calculateGainModal}
        title="İş Emri Kâr Durumu"
        onCancel={() => clearCalculateModal()}
        onOk={() => {
          if (selectedWorkOrder) {
            form.validateFields().then((values) => {
              otherApi
                .applyGain(selectedWorkOrder, values)
                .then(() => clearCalculateModal())
                .finally(() => crudPageRef.current?.refreshData());
            });
          }
        }}
        okText="Carilere İşle"
        cancelText="Vazgeç"
      >
        {calculateGainLoading ? (
          <h3 style={{ marginRight: "1em" }}>Kâr Durumu Hesaplanıyor..</h3>
        ) : (
          <>
            <h3 style={{ marginRight: "1em" }}>
              Toplam Kâr : {summaryGain.toFixed(2)} TL
            </h3>
            <Form form={form}>
              <Form.List name="gains">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ name }, index) => (
                      <Row
                        key={index}
                        style={{
                          border: "1px dashed black",
                          margin: "1em 0px ",
                          padding: "1em 0px 0px 1em",
                        }}
                      >
                        <Col span={24}>
                          <Row style={{ marginBottom: "1em" }}>
                            <Col span={20}>
                              <Row justify="center">Kâr Dağılımı</Row>
                            </Col>
                            <Col span={4}>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Col>
                          </Row>
                          <>
                            <Form.Item
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 16 }}
                              label="Cari Adı"
                              name={[name, "account"]}
                              rules={[
                                { required: true, message: "Boş bırakılamaz!" },
                              ]}
                            >
                              <Select showSearch options={accounts} />
                            </Form.Item>
                            <Form.Item
                              labelCol={{ span: 6 }}
                              wrapperCol={{ span: 18 }}
                              label="Kâr Tutarı"
                              name={[name, "gain"]}
                              rules={[
                                { required: true, message: "Boş bırakılamaz!" },
                              ]}
                            >
                              <InputNumber precision={2} min={0} stringMode />
                            </Form.Item>
                          </>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Kâr Ekle
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </>
        )}
      </Modal>
      <Modal
        width="50%"
        visible={openedWOModal}
        title="Açık İş Emirleri"
        onCancel={() => clearOpenedWOModal()}
        onOk={() => {
          opendWOForm.validateFields().then((values) => {
            setOpenedWOs([]);
            const categoryId = Util.extractIdFromIri(
              values.category,
              "categories"
            );
            otherApi
              .exportOpenedWOs(categoryId.toString())
              .then((response) => window.open("/" + response.path, "_blank"))
              .finally(() => clearOpenedWOModal());
          });
        }}
        okButtonProps={{ disabled: openedWOs.length === 0 }}
        okText="Excel Olarak Çıktı Al"
        cancelText="Vazgeç"
      >
        <Form form={opendWOForm}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            label="Kategori"
            name="category"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Select showSearch options={categories} />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                opendWOForm.validateFields().then((values) => {
                  setOpenedWOLoading(true);
                  const categoryId = Util.extractIdFromIri(
                    values.category,
                    "categories"
                  );
                  otherApi
                    .getOpenedWOs(categoryId.toString())
                    .then((response) => {
                      setOpenedWOs(response);
                    })
                    .finally(() => setOpenedWOLoading(false));
                });
              }}
            >
              Açık İş Emirlerini Getir
            </Button>
          </Form.Item>
          {openedWOLoading ? (
            <h3 style={{ marginRight: "1em" }}>İş Emirleri Çekiliyor..</h3>
          ) : (
            <table style={{ width: "100%", marginTop: "1em" }}>
              <tr style={cellStyle}>
                <th style={cellStyle}>No</th>
                <th style={cellStyle}>Fiş No</th>
                <th style={cellStyle}>İş Emri</th>
                <th style={cellStyle}>Onay Tarihi</th>
                <th style={cellStyle}>Geçen Süre</th>
                <th style={cellStyle}>Maliyet</th>
                <th style={cellStyle}>Fatura</th>
                <th style={cellStyle}>Kdv</th>
                <th style={cellStyle}>Genel Toplam</th>
                <th style={cellStyle}>Kazanç</th>
              </tr>
              {openedWOs.map((openedWO: any) => {
                return (
                  <tr>
                    <td style={cellStyle}>{openedWO.id}</td>
                    <td style={cellStyle}>{openedWO.receiptNo}</td>
                    <td style={cellStyle}>{openedWO.name}</td>
                    <td style={cellStyle}>
                      {dayjs(openedWO.approvedDate).format("DD.MM.YYYY")}
                    </td>
                    <td style={cellStyle}>{openedWO.elapsedTime}</td>
                    <td style={cellStyle}>{openedWO.cost.toFixed(2)}</td>
                    <td style={cellStyle}>{openedWO.invoice.toFixed(2)}</td>
                    <td style={cellStyle}>{openedWO.kdv.toFixed(2)}</td>
                    <td style={cellStyle}>{openedWO.summary.toFixed(2)}</td>
                    <td style={cellStyle}>{openedWO.gain.toFixed(2)}</td>
                  </tr>
                );
              })}
            </table>
          )}
        </Form>
      </Modal>
      <Modal
        visible={offerWOModal}
        title="Proforma Oluştur"
        onCancel={() => clearOfferWOModal()}
        onOk={() => {
          offerWOForm.validateFields().then((values) => {
            otherApi
              .exportOfferWOs(values)
              .then((response) => {
                crudPageRef.current?.refreshData();
                window.open("/" + response.path, "_blank");
              })
              .finally(() => clearOfferWOModal());
          });
        }}
        okText="Proforma Oluştur"
        cancelText="Vazgeç"
      >
        <Form form={offerWOForm}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            label="Proforma Adı"
            name="name"
            rules={[
              { required: true, message: "Boş bırakılamaz!" },
              { min: 3, message: "En az 3 karakter olabilir!" },
              { max: 500, message: "En fazla 500 karakter olabilir!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            label="İş Emirleri"
            name="workOrders"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Select
              allowClear
              mode="multiple"
              options={mappedWOs}
              filterOption={(input, option: any) =>
                option.label
                  .toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WOCrudPage;
