import { proformaHistoryCrudApi } from "../../client/EntityApi";
import { getProformaHistoryCPColumns } from "../../components/crud/ProformaHistoryCPComponents";
import CrudPageProcessButton from "../../components/CrudPageProcessButton";
import CrudPageV2 from "./CrudPageV2";
import { FilePdfOutlined } from "@ant-design/icons";

const ProformaHistoryCrudPage = () => {
  return (
    <CrudPageV2
      getAll={proformaHistoryCrudApi.getAll}
      api={proformaHistoryCrudApi}
      hideDefaultTitleProcess
      hideEditProcess={() => true}
      entityLabel="Proforma"
      searchKey="name"
      columns={getProformaHistoryCPColumns()}
      addForm={<></>}
      editForm={<></>}
      proceses={(row: any) => {
        return (
          <CrudPageProcessButton
            tooltipText="Proforma İndir"
            onClick={() => window.open("/" + row.fileName, "_blank")}
            icon={<FilePdfOutlined />}
          />
        );
      }}
      setEditFields={() => {}}
    />
  );
};

export default ProformaHistoryCrudPage;
