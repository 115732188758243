import { Checkbox, Form, Input, Select, Tag } from "antd";
import { Util } from "../../helper/Util";

export const getUserCrudPageColumns = (roles: any) => {
  return [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İsim Soyisim",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Aktif Mi?",
      key: "isActive",
      dataIndex: "isActive",
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>{value ? "Aktif" : "Pasif"}</Tag>
      ),
    },
    {
      title: "Roller",
      dataIndex: "roles",
      key: "roles",
      render: (values: any) =>
        values.map((value: any, index: any) => {
          return <Tag key={index}>{Util.getConstantValue(value, roles)}</Tag>;
        }),
    },
    {
      title: "Kategoriler",
      dataIndex: "categories",
      key: "categories",
      render: (values: any) =>
        values.map((value: any, index: any) => {
          return <Tag key={index}>{value.name}</Tag>;
        }),
    },
  ];
};

export const getUserCrudPageFormItems = (roles: any, categories: any) => {
  return (
    <>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="İsim Soyisim"
        name="fullName"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kullanıcı Adı"
        name="username"
        rules={[
          { required: true, message: "Boş bırakılamaz!" },
          { max: 255, message: "En fazla 255 karakter olabilir!" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Şifre"
        name="plainPassword"
        rules={[{ min: 4, message: "En az 4 karakter olmalıdır!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Aktif Mi?"
        name="isActive"
        initialValue={true}
        valuePropName="checked"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Roller"
        name="roles"
        rules={[{ required: true, message: "Boş bırakılamaz!" }]}
      >
        <Select allowClear mode="multiple" options={roles} />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        label="Kategoriler"
        name="categories"
      >
        <Select allowClear mode="multiple" options={categories} />
      </Form.Item>
    </>
  );
};
