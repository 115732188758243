import { Button, Layout, Menu, PageHeader, Tag } from "antd";

import { Header } from "antd/lib/layout/layout";
import SubMenu from "antd/lib/menu/SubMenu";
import { useNavigate } from "react-router-dom";
import { AuthHelper } from "../helper/AuthHelper";
import { UserOutlined, FileProtectOutlined } from "@ant-design/icons";

const { Sider, Content } = Layout;

const SidebarLayout = (props: any) => {
  const navigate = useNavigate();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: "0px" }}>
        <PageHeader
          style={{
            backgroundColor: "#7dbcea",
            color: "white",
            height: "64px",
          }}
          title={process.env.REACT_APP_PROJECT_NAME}
          extra={[
            <Tag key={1}>{AuthHelper.getUserField("fullName")}</Tag>,
            <Button key={2} onClick={() => AuthHelper.logout()}>
              Çıkış Yap
            </Button>,
          ]}
        />
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu mode="inline" style={{ height: "100%", borderRight: 0 }}>
            {AuthHelper.checkRole("ROLE_USER") && (
              <SubMenu
                key="sub-menu-0"
                icon={<FileProtectOutlined />}
                title="İş Önizleme"
              >
                <Menu.Item
                  key="sub-menu-0-item-1"
                  onClick={() => navigate("/admin/is-emirleri")}
                >
                  İş Emirleri
                </Menu.Item>
              </SubMenu>
            )}
            {AuthHelper.checkRole("ROLE_AUTO") && (
              <SubMenu
                key="sub-menu-1"
                icon={<FileProtectOutlined />}
                title="İş Takibi"
              >
                <Menu.Item
                  key="sub-menu-1-item-1"
                  onClick={() => navigate("/admin/is-emirleri-listesi")}
                >
                  İş Emirleri
                </Menu.Item>
              </SubMenu>
            )}
            {AuthHelper.checkRole("ROLE_ADMIN") && (
              <>
                <SubMenu
                  key="sub-menu-2"
                  icon={<FileProtectOutlined />}
                  title="İş Yönetimi"
                >
                  <Menu.Item
                    key="sub-menu-2-item-1"
                    onClick={() => navigate("/admin/faturalar")}
                  >
                    Faturalar
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-2-item-2"
                    onClick={() => navigate("/admin/is-emirleri-yonetim")}
                  >
                    İş Emirleri
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-2-item-4"
                    onClick={() => navigate("/admin/proforma")}
                  >
                    Proformalar
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-2-item-3"
                    onClick={() => navigate("/admin/cariler")}
                  >
                    Cariler
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-2-item-5"
                    onClick={() => navigate("/admin/analiz")}
                  >
                    Analiz
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sub-menu-3"
                  icon={<UserOutlined />}
                  title="Diğer İşlemler"
                >
                  <Menu.Item
                    key="sub-menu-3-item-1"
                    onClick={() => navigate("/admin/urunler")}
                  >
                    Ürünler
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-3-item-2"
                    onClick={() => navigate("/admin/kategoriler")}
                  >
                    Kategoriler
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-3-item-3"
                    onClick={() => navigate("/admin/kullanicilar")}
                  >
                    Kullanıcılar
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-3-item-4"
                    onClick={() => navigate("/admin/notlar")}
                  >
                    Notlar
                  </Menu.Item>
                  <Menu.Item
                    key="sub-menu-3-item-5"
                    onClick={() => navigate("/admin/hesap-defteri")}
                  >
                    Hesap Defteri
                  </Menu.Item>
                </SubMenu>
              </>
            )}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content style={{ margin: "3em" }}>{props.children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;
